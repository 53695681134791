import React, { useRef } from 'react'

import Main from 'layouts/Main'
import { AppDataContext, buildNavigationProps, NavigationProps } from '~/lib/contexts/AppContexts'
import { removeUndefinesFromPageProps } from '~/lib/helpers'
import NotFound from '~/components/NotFound'

interface Props {
    navigationProps: NavigationProps
}

const NotFoundPage: React.FC<Props> = ({ navigationProps }) => {

    return (
        <AppDataContext.Provider value={{
            navigationProps,
            subscribeRef: useRef(),
        }}>
            <Main>
                <NotFound/>
            </Main>
        </AppDataContext.Provider>
    )
}

export default NotFoundPage

export async function getStaticProps({ params }) {
    const props: Props = {
        navigationProps: buildNavigationProps(),
    }

    return { props: removeUndefinesFromPageProps(props) }
}